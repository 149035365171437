import { modalAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/styled-system';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

const xl = defineStyle({
  maxH: '1000px',
  overflow: 'auto',
  minW: '80vw',
  maxW: '100px',
  padding: '8px',
  borderRadius: '12px'
});

const header = defineStyle({
  paddingBottom: '20px'
});

const sizes = {
  xl: definePartsStyle({
    dialog: xl,
    header: header
  })
};

export const modalTheme = defineMultiStyleConfig({
  sizes,
  defaultProps: {
    size: 'xl'
  }
});
