import { CheckboxGroupProps, StackProps } from '@chakra-ui/react';
import { CheckboxFilter } from './CheckboxFilter.tsx';
import { useServerSelectOptionsQuery } from '../hooks/useServerSelectOptionQuery.tsx';
import { ControllerRoutes } from '../../../constants/controllerRoutes.ts';
import { useEffect, useState } from 'react';
import { debounce } from 'lodash';

type ProductFormFilterProps = Omit<CheckboxGroupProps, 'onChange' | 'options' | 'value'> & {
  hideLabel?: boolean;
  label?: string;
  value?: Array<number>;
  onChange?: (value: number[]) => void;
  spacing?: StackProps['spacing'];
  showSearch?: boolean;
};

export const ProductFormFilter = (props: ProductFormFilterProps) => {
  const { onChange, value: initialValue, ...rest } = props;
  const [value, setValue] = useState(initialValue?.map((item) => String(item)) ?? []);

  useEffect(() => {
    const debouncedOnChange = debounce((newValue: string[] | undefined) => {
      if (onChange) {
        onChange(newValue?.map((item) => Number(item)) ?? []);
      }
    }, 300);
    debouncedOnChange(value);
  }, [value, onChange]);

  const options = useServerSelectOptionsQuery('productForms', ControllerRoutes.ProductForm.Select).data ?? [];

  return <CheckboxFilter onChange={setValue} options={options} value={value} {...rest} />;
};
