import { ChakraFormDialog, FormControls, useHookForm } from '@cksoftware/react-base';
import { Box, Heading, Stack, StackProps, UseDisclosureReturn } from '@chakra-ui/react';
import { CustomProductRequestFormModel } from './types.ts';
import { CustomProductRequestForm } from './components/customProductRequestForm.tsx';
import { useCustomRequestStateStore } from '../hooks/useCustomRequestStore.tsx';
import { getAxios } from '../../../util/axios.ts';

type Props = {
  disclosure: UseDisclosureReturn;
  rootProps?: StackProps;
};

export const CustomProductRequestModal = (props: Props) => {
  const { disclosure, rootProps } = props;

  const { formMethods } = useHookForm<CustomProductRequestFormModel>({
    drugId: undefined!,
    formId: undefined!,
    usageId: undefined!,
    strength: undefined!,
    packSize: undefined!,
    strengthUnitId: undefined!,
    strengthUnitDenominatorId: undefined!,
    flavorId: undefined!,
    itemNote: undefined!,
    quantity: 1
  });

  const requestStore = useCustomRequestStateStore();

  const onSubmit = async (data: CustomProductRequestFormModel) => {
    if (!data.drugId || !data.formId || !data.usageId || !data.strength || !data.strengthUnitId || !data.packSize) {
      return;
    }

    //total hack for now
    const productInformation = await getAxios().post<{
      DrugName: string;
      FormName: string;
      UsageName: string;
      StrengthUnitName: string;
      FlavorName: string;
    }>('/order/CustomRequestInformation', {
      DrugId: data.drugId,
      FormId: data.formId,
      UsageId: data.usageId,
      StrengthUnitId: data.strengthUnitId,
      StrengthDenominatorUnitId: data.strengthUnitDenominatorId,
      FlavorId: data.flavorId
    });

    requestStore.addItem({
      DrugId: data.drugId,
      FormId: data.formId,
      StrengthUnitId: data.strengthUnitId,
      StrengthDenominatorUnitId: data.strengthUnitDenominatorId,
      FlavorId: data.flavorId,
      Strength: data.strength,
      PackSize: data.packSize,
      ItemComment: data.itemNote,
      Quantity: data.quantity,
      DrugName: productInformation.data.DrugName,
      FormName: productInformation.data.FormName,
      StrengthUnit: productInformation.data.StrengthUnitName,
      Flavor: productInformation.data.FlavorName,
      StrengthDenominator: productInformation.data.StrengthUnitName
    });

    formMethods.reset();
    disclosure.onClose();
  };

  return (
    <ChakraFormDialog<CustomProductRequestFormModel>
      disclosure={disclosure}
      formMethods={formMethods}
      onSubmit={onSubmit}
      footerRender={<FormControls submitButtonText={'Add To Cart'} submitButtonProps={{ style: { width: '100%' } }} />}
      size={{ base: 'full', md: 'lg' }}
      hideControls={true}>
      <Stack direction={{ base: 'column', md: 'row' }} spacing={{ base: '8', lg: '16' }} {...rootProps}>
        <Box flex='1'>
          <Stack spacing={{ base: '4', md: '8' }}>
            <Stack spacing={{ base: '2', md: '4' }}>
              <Heading size='lg' fontWeight='medium'>
                Product Request
              </Heading>
            </Stack>
            <CustomProductRequestForm formMethods={formMethods} />
          </Stack>
        </Box>
      </Stack>
    </ChakraFormDialog>
  );
};
