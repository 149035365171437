import { useMutation } from '@tanstack/react-query';
import { OrderRequestModel } from '../types.ts';
import { getAxios } from '../../../../util/axios.ts';
import { ControllerRoutes } from '../../../../constants/controllerRoutes.ts';

export function usePlaceOrderMutation() {
  return useMutation({
    mutationFn: async (model: OrderRequestModel) => await getAxios().post<void>(ControllerRoutes.order.place, model)
  });
}
