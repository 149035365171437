const queryStringArray = (name: string, array: any[]) => {
  if (!array || array.length === 0) {
    return ``;
  }
  let toReturn = '';
  array.forEach((val) => {
    toReturn += `${name}=${val}&`;
  });
  return toReturn;
};

export const ControllerRoutes = {
  Util: {
    UsersInRole: '/UsersInRole',
    selectOption: {
      enumType: (type: string) => `/selectOption/enum/${type}`,
      authorizedVets: `/selectOption/authorizedVets`
    }
  },
  Drug: {
    Select: '/Drug/Select'
  },
  Unit: {
    Base: '/Unit',
    Select: '/Unit/Select'
  },

  ProductRequest: {
    Base: '/ProductRequest'
  },
  ProductListing: {
    Base: '/ProductListing'
  },
  ProductForm: {
    Base: '/ProductForm',
    Select: '/ProductForm/Select'
  },
  Companies: {
    Base: '/Company'
  },
  Flavor: {
    Select: '/Flavor/Select'
  },
  Usage: {
    Select: '/Usage/Select'
  },
  order: {
    place: '/order',
    items: (itemIds: number[]) => `/order/items/?${queryStringArray('itemIds', itemIds)}`
  },
  ProductAdmin: {
    Unit: {
      Base: '/ProductAdmin/StrengthUnit',
      Select: '/ProductAdmin/StrengthUnit/Select'
    },
    Form: {
      Base: '/ProductAdmin/Form',
      Select: '/ProductAdmin/Form/Select'
    },
    Flavor: {
      Base: '/ProductAdmin/Flavor',
      Select: '/ProductAdmin/Flavor/Select'
    },
    Usage: {
      Base: '/ProductAdmin/Usage',
      Select: '/ProductAdmin/Usage/Select'
    },
    Drugs: '/ProductAdmin/Drug'
  }
};
