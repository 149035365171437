import { UseFormReturn } from 'react-hook-form';
import { CustomProductRequestFormModel } from '../types.ts';
import { createFieldHelper, FlexFormFields } from '@cksoftware/react-base';
import { Flex } from '@chakra-ui/react';
import { useCustomProductRequestFormFields } from '../util/useCustomProductRequestFormFields.tsx';
import { QuantityPicker } from '../../productView/components/QuantityPicker.tsx';

type Props = {
  formMethods: UseFormReturn<CustomProductRequestFormModel>;
};

export const CustomProductRequestForm = (props: Props) => {
  const fieldHelper = createFieldHelper<CustomProductRequestFormModel>(props.formMethods);
  const fields = useCustomProductRequestFormFields(props.formMethods, fieldHelper);
  const formGap = '8px';

  return (
    <Flex flexDir={'column'} gap={{ base: '8', lg: '16' }}>
      <Flex flexDir={'column'} gap={formGap}>
        <FlexFormFields formFields={fields} />
        <QuantityPicker
          max={99}
          min={1}
          value={props.formMethods.getValues('quantity')}
          onChange={(value) =>
            props.formMethods.setValue('quantity', value, {
              shouldValidate: true,
              shouldTouch: true,
              shouldDirty: true
            })
          }
        />
      </Flex>
    </Flex>
  );
};
