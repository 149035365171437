import { Box, BoxProps } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';

type AppProps = {
  containerProps?: BoxProps;
};

export const AppLayout = (props: AppProps) => (
  <Box {...props.containerProps}>
    <Outlet />
  </Box>
);
