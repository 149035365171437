import { ControllerRoutes } from '../../../../constants/controllerRoutes.ts';
import { useQuery } from '@tanstack/react-query';
import { useShoppingCartStore } from '../../hooks/useShoppingCartStore.tsx';
import { useMemo } from 'react';
import { getAxios } from '../../../../util/axios.ts';
import { ProductListingModel } from '../../productView/models/ProductListingModel.ts';
import { ShoppingCartItemModel } from '../../models/ShoppingCartItemModel.ts';

export type CombinedCartData = {
  StorageId: number;
  CartItem?: ShoppingCartItemModel;
  Form: string | undefined;
  Strength: number | undefined;
  StrengthUnit: string | undefined;
  PackSize: number | undefined;
  Price: number | undefined;
  DrugName: string | undefined;
  Flavour: string | undefined;
  Quantity: number | undefined;
  ItemNote: string | undefined;
};
export const useShoppingCartData = () => {
  const shoppingCart = useShoppingCartStore();
  const itemIds = useMemo(() => {
    return shoppingCart.items.map((item) => item.data.DrugId);
  }, [shoppingCart.items]);

  const productQuery = useQuery({
    queryKey: ['cartProducts', itemIds],
    queryFn: async () => {
      return (await getAxios().get<ProductListingModel[]>(ControllerRoutes.order.items(itemIds))).data;
    }
  });

  const cartItems = useMemo(() => {
    const toReturn: Array<CombinedCartData> = [];
    shoppingCart.items.forEach((item) => {
      const matchingProduct = productQuery.data?.find((val) => val.Id === item.data.DrugId);
      const matchingOption = matchingProduct?.ProductOptions.find((val) => val.Id === item.data.ProductOptionId);
      const matchingPack = matchingOption?.Packs.find((val) => val.Id === item.data.ProductPackId);
      const matchingFlavor = matchingOption?.Flavors.find((val) => Number(val.Value) === item.data.FlavorId);
      if (matchingProduct && matchingOption && matchingPack && matchingFlavor) {
        toReturn.push({
          StorageId: item.id,
          CartItem: item.data,
          DrugName: matchingProduct.DrugName,
          Form: matchingOption.Form,
          Strength: matchingOption.Strength,
          StrengthUnit: matchingOption.StrengthUnit,
          PackSize: matchingPack.Quantity,
          Price: matchingPack.Price,
          Flavour: matchingFlavor.Name,
          Quantity: item.data.Quantity,
          ItemNote: item.data.ItemComment
        });
      }
    });

    shoppingCart.customItems.forEach((item) => {
      toReturn.push({
        StorageId: item.id,
        DrugName: item.data.DrugName,
        Form: item.data.FormName,
        Strength: item.data.Strength,
        StrengthUnit: item.data.StrengthUnit,
        PackSize: item.data.PackSize,
        Flavour: item.data.Flavor,
        Price: undefined,
        Quantity: item.data.Quantity,
        ItemNote: item.data.ItemComment
      });
    });

    return toReturn;
  }, [productQuery.data, shoppingCart.customItems, shoppingCart.items]);

  return {
    combinedItems: cartItems,
    setQuantity: shoppingCart.setQuantity,
    removeItem: shoppingCart.removeItem,
    clear: shoppingCart.clear,
    productItems: shoppingCart.items,
    customItems: shoppingCart.customItems
  };
};
