import { Circle, SquareProps, useColorModeValue as mode } from '@chakra-ui/react';
import { useCartCount } from '../hooks/useShoppingCartStore.tsx';

export const CartCount = (props: SquareProps) => {
  const totalItems = useCartCount();

  if (totalItems === 0) return null;

  return (
    <Circle
      fontSize='xs'
      fontWeight='semibold'
      centerContent
      position='absolute'
      top='-2'
      right='-4'
      bg={mode('blue.500', 'blue.300')}
      color={mode('white', 'gray.800')}
      width='5'
      height='5'
      {...props}>
      {totalItems}
    </Circle>
  );
};
