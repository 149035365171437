import { UseFormReturn } from 'react-hook-form';
import { createFieldHelper, formatCurrency, SelectOption } from '@cksoftware/react-base';
import { useMemo } from 'react';
import { ProductListingModel } from '../models/ProductListingModel.ts';
import { ShoppingCartItemModel } from '../../models/ShoppingCartItemModel.ts';

export const useProductViewFormFields = (
  formMethods: UseFormReturn<ShoppingCartItemModel>,
  product: ProductListingModel,
  productOptionId: number | undefined
) => {
  const fieldHelper = createFieldHelper<ShoppingCartItemModel>(formMethods);

  const formSelect: SelectOption[] = useMemo(() => {
    if (!product.ProductOptions) {
      return [];
    }
    return product.ProductOptions.map((val) => {
      return { Name: `${val.Form} - ${val.Strength}/${val.StrengthUnit}`, Value: val.Id };
    });
  }, [product.ProductOptions]);

  const flavourSelect: SelectOption[] = useMemo(() => {
    if (!productOptionId) {
      return [];
    }
    const matchingProduct = product.ProductOptions.find((val) => val.Id === productOptionId);
    if (!matchingProduct) {
      return [];
    }
    return matchingProduct.Flavors;
  }, [productOptionId, product.ProductOptions]);

  const packSelect: SelectOption[] = useMemo(() => {
    if (!productOptionId) {
      return [];
    }
    const matchingProduct = product.ProductOptions.find((val) => val.Id === productOptionId);
    if (!matchingProduct) {
      return [];
    }
    return matchingProduct.Packs.map((val) => {
      return { Name: `${val.Quantity} - ${formatCurrency(val.Price, 2, 2)}`, Value: val.Id };
    });
  }, [productOptionId, product.ProductOptions]);

  return useMemo(() => {
    return [
      fieldHelper
        .selectInput('ProductOptionId', { items: formSelect })
        .withLabel('Product Form')
        .withValidation({ required: {} }),
      fieldHelper
        .selectInput('FlavorId', { items: flavourSelect })
        .withLabel('Flavor')
        .withValidation({ required: {} })
        .withInputProps({
          isReadOnly: !productOptionId
        }),
      fieldHelper
        .selectInput('ProductPackId', { items: packSelect })
        .withLabel('Pack')
        .withValidation({ required: {} })
        .withInputProps({
          isReadOnly: !productOptionId,
          disableSort: true
        }),
      fieldHelper.textArea('ItemComment').withLabel('Order Note')
    ];
  }, [fieldHelper, formSelect, flavourSelect, packSelect, productOptionId]);
};
