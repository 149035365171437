import { CreateFieldHelperReturn } from '@cksoftware/react-base';
import { CheckoutFormModel, DeliveryType, PatientType } from '../types.ts';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { useMemo } from 'react';
import { ControllerRoutes } from '../../../../constants/controllerRoutes.ts';
import { masks } from '../../../../constants/masks.ts';
import { Box } from '@chakra-ui/react';

export const useCheckoutFormFields = (
  formMethods: UseFormReturn<CheckoutFormModel>,
  fieldHelper: CreateFieldHelperReturn<CheckoutFormModel>
) => {
  const patientType = useWatch({
    control: formMethods.control,
    name: 'PatientType'
  });

  const deliveryType = useWatch({
    control: formMethods.control,
    name: 'DeliveryType'
  });

  return useMemo(() => {
    return [
      fieldHelper.numericInput('Refills').withValidation({ required: {} }),
      fieldHelper.textArea('InstructionsForUse').withValidation({ required: {} }),
      fieldHelper
        .selectInputQueryItems('AuthorizedUserId', { searchUrl: ControllerRoutes.Util.selectOption.authorizedVets })
        .withLabel('Authorized Vet')
        .isString()
        .withValidation({ required: {} }),
      fieldHelper.textArea('DeliveryComments'),
      fieldHelper
        .selectInputQueryItems('PatientType', {
          searchUrl: ControllerRoutes.Util.selectOption.enumType('PatientType')
        })
        .withValidation({ required: {} }),
      fieldHelper
        .textInput('PetName')
        .withRenderIf(() => {
          return patientType == PatientType.Pet;
        })
        .withValidation({ required: {} }),
      fieldHelper
        .textInput('OwnerName')
        .withRenderIf(() => {
          return patientType == PatientType.Pet;
        })
        .withValidation({ required: {} }),
      fieldHelper
        .textInput('OwnerPhoneNumber')
        .withRenderIf(() => {
          return patientType == PatientType.Pet;
        })
        .withInputProps({
          mask: masks.phone.mask,
          placeHolder: masks.phone.placeHolder
        })
        .withValidation({ required: {} }),
      fieldHelper
        .selectInputQueryItems('DeliveryType', {
          searchUrl: ControllerRoutes.Util.selectOption.enumType('DeliveryType')
        })
        .withRenderUnder(() => {
          return deliveryType == DeliveryType.DeliverToClinic ? (
            <Box fontSize={'xs'} marginTop={'-8px'} fontStyle={'italic'}>
              Delivery will be sent to address on file
            </Box>
          ) : (
            <></>
          );
        })
        .withValidation({ required: {} }),
      fieldHelper
        .addressInput('DeliveryAddress', {
          countryField: 'DeliveryAddress.Country',
          hasSelectedAddressField: 'DeliveryAddress.HasSelectedAddress',
          postalCodeField: 'DeliveryAddress.PostalCode',
          selectedAddressStringField: 'DeliveryAddress.AddressSearchString',
          streetField: 'DeliveryAddress.AddressLineOne',
          cityField: 'DeliveryAddress.City',
          provinceField: 'DeliveryAddress.Province'
        })
        .withLabel('Patient Address')
        .withRenderIf(() => {
          return deliveryType == DeliveryType.DeliverToPatient;
        })
    ];
  }, [fieldHelper, patientType, deliveryType]);
};
