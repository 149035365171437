import { Icon, Input, InputGroup, InputLeftElement } from '@chakra-ui/react';
import { RiSearchLine } from 'react-icons/ri';

type Props = {
  setSearchValue: (value: string) => void;
};

export const SearchInput = (props: Props) => {
  return (
    <InputGroup>
      <InputLeftElement>
        <Icon as={RiSearchLine} color='gray.500' fontSize='lg' />
      </InputLeftElement>
      <Input
        focusBorderColor='blue.500'
        width='full'
        fontSize='sm'
        variant='filled'
        type='text'
        placeholder='What are you looking for?'
        autoComplete='off'
        onChange={(event) => props.setSearchValue(event.target.value)}
      />
    </InputGroup>
  );
};
