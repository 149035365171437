import { keepPreviousData, QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { PagingListReturnModel } from '@cksoftware/react-base';
import { getAxios } from '../../../util/axios.ts';
import { ControllerRoutes } from '../../../constants/controllerRoutes.ts';
import { ProductListingModel } from '../productView/models/ProductListingModel.ts';

type genericCrudFilterState = {
  includeDisabled?: boolean;
  globalFilter?: string;
};

export type ProductFilterState = genericCrudFilterState & {
  formIds?: number[] | undefined;
  keyword?: string;
};

export type PagingState = {
  page: number;
  pageSize: number;
};

export type SortBy = 'packSizePrice';

export type SortingState = {
  orderBy?: SortBy;
  isDesc?: boolean;
};

type ProductParams = {
  pagingState: PagingState;
  sortingState: SortingState;
  filterState?: ProductFilterState;
};

const productKeys = {
  all: [{ scope: 'products' }] as const,
  lists: () => [{ ...productKeys.all[0], entity: 'list' }] as const,
  list: (paging: PagingState, sorting: SortingState, filter?: ProductFilterState) =>
    [{ ...productKeys.lists()[0], paging, sorting, filter }] as const,
  details: () => [...productKeys.all, 'detail'] as const,
  detail: (id: number) => [{ ...productKeys.details(), id }] as const
};

async function fetchProductTypes({
  queryKey: [{ paging, sorting, filter }]
}: QueryFunctionContext<ReturnType<(typeof productKeys)['list']>>) {
  return (
    await getAxios().get<PagingListReturnModel<ProductListingModel>>(ControllerRoutes.ProductListing.Base, {
      params: { ...paging, ...sorting, ...filter },
      paramsSerializer: {
        indexes: null // use brackets with indexes
      }
    })
  ).data;
}

export function useProductListing(params: ProductParams) {
  return useQuery({
    queryKey: productKeys.list(params.pagingState, params.sortingState, params.filterState),
    queryFn: fetchProductTypes,
    placeholderData: keepPreviousData
  });
}
