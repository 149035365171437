import { extendTheme } from '@chakra-ui/react';
import { ChakraThemeConfig } from '@cksoftware/react-base';
import { alertTheme } from './components/alert';
import { buttonTheme } from './components/button';
import { cardTheme } from './components/card';
import { drawerTheme } from './components/drawer';
import { formTheme } from './components/form';
import { formLabelTheme } from './components/formLabel';
import { headerTheme } from './components/heading';
import { inputTheme } from './components/input';
import { modalTheme } from './components/modal';
import { numberInputTheme } from './components/numberInput';
import { selectTheme } from './components/select';
import { switchTheme } from './components/switch';
import { tabsTheme } from './components/tab';
import { tableTheme } from './components/table';
import { textTheme } from './components/text';

const themeConfig: ChakraThemeConfig = {
  paddings: {
    tableHeightBottomPadding: 190
  }
};

export const ChakraTheme = extendTheme({
  ...themeConfig,
  components: {
    Input: inputTheme,
    Button: buttonTheme,
    Select: selectTheme,
    NumberInput: numberInputTheme,
    Form: formTheme,
    Text: textTheme,
    Modal: modalTheme,
    Heading: headerTheme,
    Tabs: tabsTheme,
    Card: cardTheme,
    Table: tableTheme,
    Switch: switchTheme,
    Drawer: drawerTheme,
    Alert: alertTheme,
    FormLabel: formLabelTheme
  },

  styles: {
    global: {
      body: {
        background: '#faf9f67f'
      }
    }
  },
  colors: {
    primary: {
      50: '#e3f2fd', // Lightest
      100: '#bbdefb',
      200: '#90caf9',
      300: '#64b5f6',
      400: '#42a5f5',
      500: '#2196f3', // Base color
      600: '#1e88e5',
      700: '#1976d2',
      800: '#1565c0',
      900: '#0d47a1'
    },
    secondary: {
      50: '#fdeee7',
      100: '#fbd3c4',
      200: '#f7b59b',
      300: '#f39672',
      400: '#ef7a54',
      500: '#d95b09', // Base color
      600: '#b44708',
      700: '#8e3506',
      800: '#682404',
      900: '#431602'
    },
    tertiary: {
      50: '#f4e0e8',
      100: '#e0b3c4',
      200: '#c8809b',
      300: '#b14d72',
      400: '#9d2664',
      500: '#64154a', // Base color
      600: '#5a1e3f',
      700: '#441434',
      800: '#2d0b24',
      900: '#180613'
    }
  }
});
