import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { ItemStore, itemStoreMethods } from '../../utils/itemStoreMethods.tsx';
import { ShoppingCartItemModel } from '../../models/ShoppingCartItemModel.ts';

export const useShoppingCartItemStore = create(
  persist<ItemStore<ShoppingCartItemModel>>((set) => itemStoreMethods<ShoppingCartItemModel>(set), {
    name: 'shopping-cart-state'
  })
);
