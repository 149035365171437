import { useQuery } from '@tanstack/react-query';
import { SelectOption } from '@cksoftware/react-base';
import { getAxios } from '../../../util/axios.ts';

function SelectOptionKeys(scope: string) {
  return {
    all: [{ scope: scope }] as const,
    lists: () => [{ ...SelectOptionKeys(scope).all[0], entity: 'list' }] as const,
    list: () => [{ ...SelectOptionKeys(scope).lists()[0] }] as const
  };
}

async function fetchSelectOptions(url: string) {
  return (await getAxios().get<SelectOption[]>(url)).data;
}

export function useServerSelectOptionsQuery(scope: string, url: string) {
  return useQuery({
    queryKey: SelectOptionKeys(scope).list(),
    queryFn: () => fetchSelectOptions(url),
    select: (data) =>
      data.map((x) => ({
        label: x.Name,
        value: x.Value as string
      }))
  });
}
