import { Box, Flex, Image, Link, Stack, Text, useColorModeValue } from '@chakra-ui/react';
import { PriceTag } from '../../../../components/common/PriceTag.tsx';
import { QuantityPicker } from './QuantityPicker.tsx';
import { CombinedCartData } from '../hooks/useShoppingCartData.tsx';

export type CartItemProps = {
  model: CombinedCartData;
  onChangeQuantity: (quantity: number) => void;
  onClickDelete: () => void;
  isRequest?: boolean;
};

export const CartItem = (props: CartItemProps) => {
  const color = useColorModeValue('gray.500', 'gray.300');

  const description = `${props.model.Form} ${props.model.Strength}${props.model.StrengthUnit} ${props.model.Flavour}`;

  return (
    <Stack direction='row' spacing='5'>
      <Image
        rounded='md'
        minWidth='24'
        maxWidth='24'
        height={{ base: '20', md: '24' }}
        fit='cover'
        // src={image.Url}
        // alt={image.AltText}
        draggable='false'
        loading='lazy'
      />
      <Stack width='full' spacing='3'>
        <Stack direction={{ base: 'column', md: 'row' }} spacing='3' alignItems='flex-start'>
          <Stack spacing='0.5' width='full'>
            <Text fontWeight='bold'>{props.model.DrugName}</Text>
            <Text fontWeight='medium'>Pack Size: {props.model.PackSize}</Text>
            <Text color={color}>{description}</Text>
          </Stack>
          {props.model.Price && <PriceTag minimumPrice={props.model.Price} isRequest={false} />}
        </Stack>
        <Flex width='full' justifyContent='space-between' alignItems='center'>
          <Box>
            <QuantityPicker
              max={99}
              min={1}
              value={props.model.Quantity}
              onChange={props.onChangeQuantity}
              rootProps={{ height: '32px' }}
            />
          </Box>
          <Link as='button' type='button' fontWeight='medium' fontSize='sm' color={color} onClick={props.onClickDelete}>
            Remove
          </Link>
        </Flex>
      </Stack>
    </Stack>
  );
};
