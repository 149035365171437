import { Box, Flex, useColorModeValue as mode, useDisclosure } from '@chakra-ui/react';
import { useFilterState } from './hooks/useFilterState.tsx';
import { CustomerNavMobile } from './components/CustomerNavMobile.tsx';
import { ProductCatalogue } from './components/ProductCatalogue.tsx';
import { MobileBottomNav } from './components/MobileBottomNav.tsx';
import { CustomerNavDesktop } from './components/CustomerNavDesktop.tsx';
import { ShoppingCart } from './shoppingCart/ShoppingCart.tsx';
import { CustomProductRequestModal } from './customProductRequest/CustomProductRequest.tsx';

export const ProductListing = () => {
  const { filterState, setKeyword, setFormIds } = useFilterState();
  const { isOpen, onOpen, onClose } = useDisclosure(); // todo should this be here?

  const customRequestDisclosure = useDisclosure(); // todo rename

  return (
    <>
      <Flex direction='column' pb='4.5rem' overflow='hidden' display={{ base: 'flex', lg: 'none' }}>
        <CustomerNavMobile setSearchValue={setKeyword} onCartClick={onOpen} />
        <Flex flex='1' fontSize='sm' overflow='auto'>
          <Box p='5' width='full' height='100%' overflowY='auto'>
            <ProductCatalogue filterState={filterState} setFormIds={setFormIds} />
          </Box>
        </Flex>
        <MobileBottomNav onCartClick={onOpen} onRequestClick={customRequestDisclosure.onOpen} />
      </Flex>
      <Box minH='100vh' display={{ base: 'none', lg: 'block' }}>
        <CustomerNavDesktop
          setSearchValue={setKeyword}
          onCartClick={onOpen}
          onRequestClick={customRequestDisclosure.onOpen}
        />
        <Box borderTopWidth='1px' borderColor={mode('gray.200', 'gray.700')} bg={mode('white', 'gray.800')}>
          <ProductCatalogue filterState={filterState} setFormIds={setFormIds} />
          {customRequestDisclosure.isOpen && <CustomProductRequestModal disclosure={customRequestDisclosure} />}
        </Box>
        <ShoppingCart isOpen={isOpen} onClose={onClose} />
        <Box bg='blackAlpha.400' pos='fixed' zIndex='-1' inset='0' />
      </Box>
    </>
  );
};
