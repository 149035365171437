import { Box, ChakraBaseProvider } from '@chakra-ui/react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { useAuth } from '@cksoftware/react-base';
import { useEffect } from 'react';
import { ErrorContainer } from '../ErrorContainer';
import { elementUser } from './globalModels/user/elementUser';
import { useNavWithRouting } from './routes/AppRouter';
import { ChakraTheme } from './theme/chakraTheme';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnMount: true,
      refetchOnWindowFocus: true
    }
  }
});

export const App = () => {
  const { initUser, hasLoaded } = useAuth<elementUser>();
  const AppRouter = createBrowserRouter(useNavWithRouting());

  useEffect(() => {
    async function initAuth() {
      await initUser();
    }
    initAuth();
  }, []);

  return (
    <Box maxHeight={'100vh'}>
      <QueryClientProvider client={queryClient}>
        <ChakraBaseProvider theme={ChakraTheme} cssVarsRoot='body' resetCSS={true}>
          <ErrorContainer>{hasLoaded && <RouterProvider router={AppRouter} />}</ErrorContainer>
        </ChakraBaseProvider>
        <ReactQueryDevtools />
      </QueryClientProvider>
    </Box>
  );
};
