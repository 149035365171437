import { BuildNavItemFromRoute, BuildRoute, NavItem, RouteError, useNavItemStore } from '@cksoftware/react-base';
import { RouteObject } from 'react-router-dom';
import { AccountManagementItems, LoginRouteItem, LogoutRouteItem, MyAccountRouteItem } from './AccountRoutes';
import { AdminNavItems } from './AdminRoutes';
import { ProductAdminNavItems } from './ProductAdminRoutes.tsx';
import { AppLayout } from '../components/layout/AppLayout.tsx';
import { ProductListing } from '../features/productListing/ProductListing.tsx';
import { CrmNavItems } from './CrmRoutes.tsx';

const AuthenticatedRouteItems = [
  AccountManagementItems,
  MyAccountRouteItem,
  AdminNavItems,
  CrmNavItems,
  LogoutRouteItem,
  ProductAdminNavItems
];
const UnauthenticatedRouteItems = [LoginRouteItem, AccountManagementItems];

export function useNavWithRouting() {
  const setNavItemStore = useNavItemStore((state) => state.set);
  const routes = new Array<RouteObject>();
  const nav: NavItem[] = [];

  routes.push({
    path: '/',
    element: <AppLayout />,
    errorElement: <RouteError />,
    children: [
      {
        path: '/',
        element: <ProductListing />,
        errorElement: <RouteError />
      }
    ]
  });

  // routes.push({
  //   path: ApplicationRoutes.ecommerce.checkout,
  //   element: <AppLayout />,
  //   errorElement: <RouteError />,
  //   children: [
  //     {
  //       path: ApplicationRoutes.ecommerce.checkout,
  //       element: <Checkout />,
  //       errorElement: <RouteError />
  //     }
  //   ]
  // });

  AuthenticatedRouteItems.forEach((value) => {
    routes.push(BuildRoute(value));
    const navItem = BuildNavItemFromRoute(value);
    if (navItem) {
      nav.push(navItem);
    }
  });

  UnauthenticatedRouteItems.forEach((value) => {
    routes.push(BuildRoute(value));
    const navItem = BuildNavItemFromRoute(value);
    if (navItem) {
      nav.push(navItem);
    }
  });

  setNavItemStore(nav);

  return routes;
}
