import { Box, Stack } from '@chakra-ui/react';
import { ProductItem } from './ProductItem.tsx';
import { PagingState, ProductFilterState, SortingState, useProductListing } from '../hooks/useProductListing.tsx';

type Props = {
  sortingState: SortingState;
  pagingState: PagingState;
  filterState: ProductFilterState;
  onProductViewOpen: (id: number) => void;
};

export const ProductList = (props: Props) => {
  const productQuery = useProductListing({
    pagingState: props.pagingState,
    sortingState: props.sortingState,
    filterState: props.filterState
  });

  return (
    <Box maxW='7xl' mx='auto' px={{ base: '4', md: '8', lg: '12' }} py={{ base: '6', md: '8', lg: '12' }}>
      <Stack spacing={{ base: '8', md: '12' }}>
        <Stack spacing='8'>
          {productQuery.data?.PagedRows.map((item) => (
            <ProductItem key={item.Id} onClickOrder={() => props.onProductViewOpen(item.Id)} {...item} />
          ))}
        </Stack>
      </Stack>
    </Box>
  );
}; //todo delete home?
