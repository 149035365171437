import { Box, Center, Flex, HStack, Image, VisuallyHidden } from '@chakra-ui/react';
import { MdMenu } from 'react-icons/md';
import logo from '../../../assets/logo.png';
import { SearchInput } from './SearchInput.tsx';

type Props = {
  setSearchValue: (value: string) => void;
  onCartClick: () => void;
};

export const CustomerNavMobile = (props: Props) => {
  return (
    <Box px='4' py='4' borderBottomWidth='1px' overflow='auto'>
      <Flex align='center' justify='space-between' mb='3' display={{ base: 'flex', lg: 'none' }}>
        <HStack spacing='3'>
          <Center w='8' h='8' as='button' type='button'>
            <VisuallyHidden>Toggle Menu</VisuallyHidden>
            <Box as={MdMenu} fontSize='3xl' />
          </Center>
          <Box flexShrink={0}>
            <Image height={'8'} fill='currentColor' src={logo} alt='logo' />
          </Box>
        </HStack>
      </Flex>
      <SearchInput setSearchValue={props.setSearchValue} />
    </Box>
  );
};
