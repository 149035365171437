import { Box, Heading, HStack, Stack, StackProps, Text, useColorModeValue } from '@chakra-ui/react';
import { QuantityPicker } from './components/QuantityPicker.tsx';
import { Gallery } from '../components/Gallery.tsx';
import { FlexForm, useHookForm } from '@cksoftware/react-base';
import { useShoppingCartItemStore } from '../shoppingCart/hooks/useShoppingCartStateStore.tsx';
import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { PriceTag } from '../../../components/common/PriceTag.tsx';
import { useProductViewFormFields } from './util/useProductViewFormFields.ts';
import { ProductListingModel } from './models/ProductListingModel.ts';
import { ShoppingCartItemModel } from '../models/ShoppingCartItemModel.ts';

interface ProductQuickShopProps {
  product: ProductListingModel;
  rootProps?: StackProps;
  onClose: () => void;
}

export const ProductView = (props: ProductQuickShopProps) => {
  const { product, rootProps } = props;

  const { formMethods } = useHookForm<ShoppingCartItemModel>({
    FlavorId: undefined!,
    ItemComment: '',
    OrderNote: '',
    ProductOptionId: undefined!,
    ProductPackId: undefined!,
    Quantity: 1,
    DrugId: product.Id
  });

  const productOptionId = useWatch({
    control: formMethods.control,
    name: 'ProductOptionId'
  });

  const productPackId = useWatch({
    control: formMethods.control,
    name: 'ProductPackId'
  });

  const quantity = useWatch({
    control: formMethods.control,
    name: 'Quantity'
  });

  const fields = useProductViewFormFields(formMethods, product, productOptionId);

  const productPrice = useMemo(() => {
    if (!productPackId) {
      return undefined;
    }
    const matchingPack = props.product.ProductOptions.flatMap((val) => val.Packs).find(
      (val) => val.Id === productPackId
    );
    if (!matchingPack) {
      return undefined;
    }
    return matchingPack.Price * quantity;
  }, [quantity, productPackId, props.product.ProductOptions]);

  const productStore = useShoppingCartItemStore();

  return (
    <Stack direction={{ base: 'column', md: 'row' }} spacing={{ base: '8', lg: '16' }} {...rootProps}>
      <Box flex='1'>
        <Gallery images={product.Images} />
      </Box>
      <Box flex='1'>
        <Stack spacing={{ base: '4', md: '8' }}>
          <Stack spacing={{ base: '2', md: '4' }}>
            <Heading size='lg' fontWeight='medium'>
              {product.DrugName}
            </Heading>
            <Text
              color={useColorModeValue('gray.600', 'gray.400')}
              dangerouslySetInnerHTML={{ __html: product.DrugDescription }}></Text>
          </Stack>
          <FlexForm
            formMethods={formMethods}
            formFields={fields}
            onSubmit={async (model: ShoppingCartItemModel) => {
              if (!model.ProductOptionId) {
                return;
              }
              productStore.addItem(model);
              props.onClose();
            }}
            buttonConfig={{
              submitButtonText: 'Add to cart',
              submitButtonProps: { style: { width: '100%' } }
            }}>
            <Stack direction={{ base: 'column', md: 'row' }} spacing={{ base: '8', lg: '16' }} {...rootProps}>
              <Box flex='1'>
                <Stack spacing={{ base: '4', md: '8' }}>
                  <HStack spacing={{ base: '4', md: '8' }} align='flex-end' justify='space-evenly'>
                    <Box flex='1'>
                      <QuantityPicker
                        max={99}
                        min={1}
                        defaultValue={1}
                        value={formMethods.getValues('Quantity')}
                        onChange={(value) =>
                          formMethods.setValue('Quantity', value, {
                            shouldValidate: true,
                            shouldTouch: true,
                            shouldDirty: true
                          })
                        }
                      />
                    </Box>
                  </HStack>
                  {productPrice && (
                    <Stack
                      direction={{ base: 'column', md: 'row' }}
                      spacing='1'
                      align='baseline'
                      justify='space-between'>
                      <Box fontWeight={'bold'}>Total Cost:</Box>
                      <PriceTag
                        minimumPrice={productPrice}
                        salePrice={undefined} //todo fix this
                        rootProps={{ fontSize: 'xl' }}
                      />
                    </Stack>
                  )}
                </Stack>
              </Box>
            </Stack>
          </FlexForm>
        </Stack>
      </Box>
    </Stack>
  );
};
