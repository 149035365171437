import { Box, Flex, HStack, Link, StackDivider, useBreakpointValue, useColorModeValue as mode } from '@chakra-ui/react';
import { PriceTag } from '../../../components/common/PriceTag.tsx';
import { ProductMeta } from './ProductMeta';
import { ProductListingModel } from '../productView/models/ProductListingModel.ts';

type ProductItemProps = ProductListingModel & {
  onClickOrder?: () => void;
};

export const ProductItem = (props: ProductItemProps) => {
  const { Images, DrugName, ProductOptions, onClickOrder, AvailableForms, Usages, MinimumPrice, MaximumPrice } = props;

  const isMobile = useBreakpointValue({ base: true, md: false });

  return isMobile ? (
    <Box>
      <Flex>
        <ProductMeta
          isOutOfStock={false}
          name={DrugName}
          usages={Usages}
          options={ProductOptions}
          image={Images[0]}
          forms={AvailableForms}
        />
        <PriceTag
          minimumPrice={MinimumPrice}
          maximumPrice={MaximumPrice}
          rootProps={{ pt: '1', fontSize: 'sm', fontWeight: 'semibold' }}
        />
      </Flex>
      <HStack
        mt='2'
        fontSize='sm'
        fontWeight='medium'
        divider={<StackDivider />}
        spacing='3'
        color={mode('blue.600', 'blue.300')}>
        <Link as='button' type='button' onClick={onClickOrder}>
          Order Now
        </Link>
      </HStack>
    </Box>
  ) : (
    <Flex align='flex-start' justify='space-between'>
      <ProductMeta
        isOutOfStock={false}
        name={DrugName}
        usages={Usages}
        options={ProductOptions}
        image={Images[0]}
        forms={AvailableForms}
      />
      <Flex direction='column' align='flex-end' justify='space-between' width='full' maxW='2xs' minH='16'>
        <PriceTag
          minimumPrice={MinimumPrice}
          maximumPrice={MaximumPrice}
          rootProps={{ pt: '1', fontSize: 'sm', fontWeight: 'semibold' }}
        />

        <Flex direction='column' align='center'>
          <HStack
            mt='2'
            fontSize='sm'
            fontWeight='medium'
            divider={<StackDivider />}
            spacing='3'
            color={mode('blue.600', 'blue.300')}>
            <Link as='button' type='button' fontWeight='medium' onClick={onClickOrder}>
              Order Now
            </Link>
          </HStack>
        </Flex>
      </Flex>
    </Flex>
  );
};
