import { Box, Flex, HStack, Image, useColorModeValue as mode } from '@chakra-ui/react';
import logo from '../../../assets/logo.png';
import { AiOutlineUser } from 'react-icons/ai';
import { SearchInput } from './SearchInput.tsx';
import { NavAction } from './NavAction.tsx';
import { CartCount } from './CartCount.tsx';
import { items } from './NavItemIcons.tsx';
import { useNavigate } from 'react-router-dom';
import { ApplicationRoutes } from '../../../constants/appRoutes.ts';
import { useAuth } from '@cksoftware/react-base';
import { AppRoles } from '../../../constants/appRoles.ts';

type Props = {
  setSearchValue: (value: string) => void;
  onCartClick: () => void;
  onRequestClick: () => void;
};

export const CustomerNavDesktop = (props: Props) => {
  const nav = useNavigate();
  const auth = useAuth();
  return (
    <Box px='8' bg={mode('white', 'gray.800')}>
      <Flex height='4.5rem' align='center' maxW='8xl' mx='auto'>
        <Box flexShrink={0}>
          <Image height={'10'} fill='currentColor' src={logo} alt='logo' />
        </Box>
        <Box width='full' mx='8'>
          <SearchInput setSearchValue={props.setSearchValue} />
        </Box>
        <HStack spacing='8' flexShrink={0}>
          {auth.isInRole([AppRoles.ProductAdmin, AppRoles.Admin]) && (
            <NavAction.Desktop {...items.admin} onClick={props.onRequestClick} />
          )}
          <NavAction.Desktop {...items.request} onClick={props.onRequestClick} />
          <Box position='relative'>
            <NavAction.Desktop {...items.cart} onClick={props.onCartClick} />
            <CartCount />
          </Box>
          <NavAction.Desktop
            label='Sign out'
            icon={AiOutlineUser}
            onClick={() => nav(ApplicationRoutes.account.logout)}
          />
        </HStack>
      </Flex>
    </Box>
  );
};
