import { Select, SelectProps, useColorModeValue } from '@chakra-ui/react';

export type sortByOption = 'low-to-high' | 'high-to-low';

const sortByOptions = {
  defaultValue: undefined,
  options: [
    { label: 'Price: Low to High', value: 'low-to-high' },
    { label: 'Price: High to Low', value: 'high-to-low' }
  ]
};

type SortBySelectProps = SelectProps & {
  onSelectSortBy: (value: sortByOption | undefined) => void;
};

export const SortBySelect = ({ onSelectSortBy, ...props }: SortBySelectProps) => (
  <Select
    size='sm'
    aria-label='Sort by'
    defaultValue={sortByOptions.defaultValue}
    focusBorderColor={useColorModeValue('blue.500', 'blue.200')}
    rounded='md'
    onChange={(event) => onSelectSortBy(event.target.value as sortByOption)}
    {...props}>
    {sortByOptions.options.map((option) => (
      <option key={option.value} value={option.value}>
        {option.label}
      </option>
    ))}
  </Select>
);
