import { Box, Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay } from '@chakra-ui/react';
import { ProductView } from './ProductView.tsx';
import { useGenericCrudGetQuery } from '@cksoftware/react-base';
import { ControllerRoutes } from '../../../constants/controllerRoutes.ts';
import { ProductListingModel } from './models/ProductListingModel.ts';

type ProductViewProps = {
  productId: number;
  isOpen: boolean;
  onClose: () => void;
};

export const ProductViewModal = (props: ProductViewProps) => {
  const { isOpen, onClose, productId } = props;
  const productQuery = useGenericCrudGetQuery<ProductListingModel>(productId, ControllerRoutes.ProductListing.Base);

  if (productQuery.isLoading) return <Box>Loading...</Box>;

  return (
    <Box height='100vh'>
      <Modal isOpen={isOpen} onClose={onClose} size='6xl'>
        <ModalOverlay />
        <ModalContent borderRadius='none' mx={{ base: '2.5', lg: '16' }} my={{ base: '2.5', md: '16' }}>
          <ModalCloseButton top='3' right='5' size='lg' />
          <ModalBody px={{ base: '5', lg: '16' }} pt='16' pb={{ base: '10', lg: '16' }}>
            {productQuery.data && <ProductView product={productQuery.data} onClose={onClose} />}
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};
