import { ChakraFormDialog, useHookForm, useStandardToast } from '@cksoftware/react-base';
import { Box, Flex, Heading, HStack, Stack, Text, UseDisclosureReturn } from '@chakra-ui/react';
import { CartItem, CartItemProps } from '../shoppingCart/components/CartItem.tsx';
import { useShoppingCartData } from '../shoppingCart/hooks/useShoppingCartData.tsx';
import { CheckoutFormModel, DeliveryType } from './types.ts';
import { CheckoutForm } from './components/checkoutForm.tsx';
import { useMemo } from 'react';
import { PriceTag } from '../../../components/common/PriceTag.tsx';
import { usePlaceOrderMutation } from './api/usePlaceOrderMutation.ts';

type Props = {
  disclosure: UseDisclosureReturn;
};

export const CheckoutModal = (props: Props) => {
  const { formMethods } = useHookForm<CheckoutFormModel>({
    AuthorizedUserId: undefined!,
    DeliveryAddress: undefined,
    DeliveryComments: undefined,
    DeliveryType: undefined!,
    InstructionsForUse: '',
    OwnerName: undefined,
    OwnerPhoneNumber: undefined,
    PatientType: undefined!,
    PetName: undefined,
    Refills: 0
  });
  const { combinedItems, productItems, customItems, setQuantity, removeItem, clear } = useShoppingCartData();

  const sum = useMemo(() => {
    let total = 0;
    combinedItems.forEach((item) => {
      if (item.Price && item.Quantity) {
        total += item.Price * item.Quantity;
      }
    });

    return total;
  }, [combinedItems]);

  const placeOrderMutation = usePlaceOrderMutation();
  const toast = useStandardToast();
  const onSubmit = async (data: CheckoutFormModel) => {
    if (data.DeliveryType != DeliveryType.DeliverToPatient) {
      data.DeliveryAddress = undefined;
    }
    await placeOrderMutation.mutateAsync({
      ...data,
      Products: productItems.map((r) => r.data),
      CustomRequests: customItems.map((r) => r.data)
    });
    toast.successToast('Order Placed', 'Thank you, your order has been submitted successfully.', 10000, true);
    clear();
    formMethods.reset();
    props.disclosure.onClose();
  };

  return (
    <ChakraFormDialog<CheckoutFormModel>
      disclosure={props.disclosure}
      formMethods={formMethods}
      onSubmit={onSubmit}
      size={{ base: 'full', md: 'xl' }}>
      <Box>
        <Flex direction={{ base: 'column', md: 'row' }} gap={{ base: '2', md: '24px' }}>
          <Box padding={{ base: '2', md: '6' }} flex={'2'}>
            <Heading size='md'>Purchase Information</Heading>
            <Box paddingTop={{ base: '2', md: '2' }}>
              <CheckoutForm formMethods={formMethods} />
            </Box>
          </Box>
          <Box padding={{ base: '6', md: '6' }} flex='1' bg={'gray.100'}>
            <Heading size='md'>{'Shopping Cart' + ` (${combinedItems.length} items)`}</Heading>
            <Stack padding={{ base: '6', md: '10' }} spacing='8'>
              <Stack>
                {combinedItems.map((item, key) => {
                  const cartItem: CartItemProps = {
                    model: item,
                    onChangeQuantity: (quantity) => setQuantity(item.StorageId, quantity),
                    onClickDelete: () => removeItem(item.StorageId)
                  };
                  return <CartItem key={key} {...cartItem} />;
                })}
              </Stack>
            </Stack>
            <Stack>
              <HStack fontSize='xl' fontWeight='semibold'>
                <Text flex='1'>Subtotal:</Text>
                <PriceTag minimumPrice={sum} />
              </HStack>
            </Stack>
          </Box>
        </Flex>
      </Box>
    </ChakraFormDialog>
  );
};
