import { inputAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(inputAnatomy.keys);

const standardInput = definePartsStyle({
  field: {
    background: 'white',
    borderRadius: '4px',
    border: '1px solid #969696',
    marginBottom: '4px',
    height: '35px',
    padding: '12px',

    _hover: {
      borderColor: '#c0bfbf'
    },
    _focus: {
      borderColor: '#1054c0',
      backgroundColor: '#fafafa'
    },
    _invalid: {
      border: '1px solid red'
    },
    _readOnly: {
      opacity: '0.5'
    }
  },
  icon: {
    borderRadius: '0'
  }
});

const smallInput = definePartsStyle({
  field: {
    background: 'white',
    borderRadius: '2px',
    border: '1px solid #707070',
    marginBottom: '2px',
    height: '20px'
  },
  icon: {
    borderRadius: '0'
  }
});

const fieldArray = definePartsStyle({
  field: {
    background: 'white',
    borderRadius: '2px',
    border: '1px solid #707070',
    w: '100%'
  },
  icon: {
    borderRadius: '0'
  }
});

export const inputTheme = defineMultiStyleConfig({
  variants: {
    standardInput,
    fieldArray,
    smallInput
  },
  defaultProps: {
    variant: 'standardInput'
  }
});
