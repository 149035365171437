import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { ItemStore, itemStoreMethods } from '../utils/itemStoreMethods.tsx';

export type CustomRequestItem = {
  DrugId: number;
  DrugName: string;
  FormId: number;
  FormName: string;
  Strength: number;
  PackSize: number;
  StrengthUnitId: number;
  StrengthUnit: string;
  StrengthDenominatorUnitId?: number;
  StrengthDenominator?: string;
  FlavorId?: number;
  Flavor?: string;
  Quantity: number;
  ItemComment: string;
};

export const useCustomRequestStateStore = create(
  persist<ItemStore<CustomRequestItem>>((set) => itemStoreMethods<CustomRequestItem>(set), {
    name: 'custom-request-state'
  })
);
