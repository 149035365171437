import { RouteAuthenticationRequirement, RouteItem } from '@cksoftware/react-base';
import { AppRoles } from '../constants/appRoles.ts';
import { NarrowLayout } from '../components/layout/narrowLayout.tsx';
import { ApplicationRoutes } from '../constants/appRoutes.ts';

export const ProductAdminNavItems: RouteItem = {
  text: 'Product Admin',
  authenticationRequirement: RouteAuthenticationRequirement.Authenticated,
  allowedRoles: [AppRoles.ProductAdmin, AppRoles.SystemAdmin],
  layout: <NarrowLayout />,
  subItems: [
    {
      text: 'Manage Products',
      authenticationRequirement: RouteAuthenticationRequirement.Authenticated,
      path: ApplicationRoutes.ProductAdmin.manageProducts,
      allowedRoles: [AppRoles.ProductAdmin],
      async lazy() {
        const component = await import('../features/productAdmin/productManagement/ManageProducts');
        return { Component: component.ManageProducts };
      }
    },
    {
      text: 'Manage Product Forms',
      authenticationRequirement: RouteAuthenticationRequirement.Authenticated,
      path: ApplicationRoutes.ProductAdmin.manageProductForms,
      allowedRoles: [AppRoles.ProductAdmin],
      async lazy() {
        const component = await import('../features/productAdmin/productFormManagement/ManageProductForms.tsx');
        return { Component: component.ManageProductForms };
      }
    },
    {
      text: 'Manage Product Strength Units',
      authenticationRequirement: RouteAuthenticationRequirement.Authenticated,
      path: ApplicationRoutes.ProductAdmin.manageProductStrengthUnits,
      allowedRoles: [AppRoles.ProductAdmin],
      async lazy() {
        const component = await import(
          '../features/productAdmin/productStrengthUnitManagement/ManageProductStrengthUnits.tsx'
        );
        return { Component: component.ManageProductStrengthUnits };
      }
    },
    {
      text: 'Manage Product Flavors',
      authenticationRequirement: RouteAuthenticationRequirement.Authenticated,
      path: ApplicationRoutes.ProductAdmin.manageProductFlavors,
      allowedRoles: [AppRoles.ProductAdmin],
      async lazy() {
        const component = await import('../features/productAdmin/productFlavorManagement/ManageProductFlavors.tsx');
        return { Component: component.ManageProductFlavors };
      }
    },
    {
      text: 'Manage Product Usages',
      authenticationRequirement: RouteAuthenticationRequirement.Authenticated,
      path: ApplicationRoutes.ProductAdmin.manageProductUsages,
      allowedRoles: [AppRoles.ProductAdmin],
      async lazy() {
        const component = await import('../features/productAdmin/productUsageManagement/ManageProductUsages.tsx');
        return { Component: component.ManageProductUsages };
      }
    }
  ]
};
