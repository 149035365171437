import { Box, HStack, Icon, Image, Stack, Text, useColorModeValue as mode } from '@chakra-ui/react';
import { HiXCircle } from 'react-icons/hi';
import { ProductImage } from '../models/ProductImage.ts';
import { ProductOptionModel } from '../productView/models/ProductListingModel.ts';

export type ProductMetaProps = {
  name: string;
  forms: string[];
  usages: string[];
  image: ProductImage;
  isOutOfStock?: boolean;
  options: ProductOptionModel[];
};

export const ProductMeta = (props: ProductMetaProps) => {
  const { image, name, forms, options, isOutOfStock, usages } = props;
  return (
    <Stack direction='row' spacing={{ base: '3', md: '5' }} width='full'>
      <Box width='24' height='24' rounded='lg' borderWidth='1px' overflow='hidden'>
        {image && (
          <Image
            fit='cover'
            src={image.Url}
            alt={image.AltText}
            draggable='false'
            loading='lazy'
            width='full'
            height='full'
          />
        )}
      </Box>
      <Box pt='2'>
        <Stack fontSize='sm' align='flex-start'>
          <Text fontWeight='semibold' lineHeight='1' noOfLines={1}>
            {name}
          </Text>
          <Text color={mode('gray.600', 'gray.400')} lineHeight='1'>
            {[...new Set(options.map((item) => `${item.Strength}${item.StrengthUnit}`))].join(', ')}
          </Text>
          {forms && (
            <Text color={mode('gray.600', 'gray.400')} lineHeight='1'>
              {[...new Set(forms.map((item) => item))].join(', ')}
            </Text>
          )}
          {usages && (
            <Text color={mode('gray.600', 'gray.400')} lineHeight='1'>
              {[...new Set(usages.map((item) => item))].join(', ')}
            </Text>
          )}

          {isOutOfStock && (
            <HStack spacing='1' color={mode('red.600', 'red.400')}>
              <Icon as={HiXCircle} fontSize='md' />
              <p>Out of stock</p>
            </HStack>
          )}
        </Stack>
      </Box>
    </Stack>
  );
};
