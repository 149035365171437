export const ApplicationRoutes = {
  account: {
    myProfile: '/my-profile',
    login: '/login',
    logout: '/logout',
    resetPassword: '/reset-password',
    confirmEmail: '/confirm-email',
    forgotPassword: '/forgot-password'
  },
  admin: {
    users: '/admin/users'
  },
  ecommerce: {
    checkout: '/checkout'
  },
  ProductAdmin: {
    manageProducts: '/products/manage-products',
    manageProductForms: '/products/manage-forms',
    manageProductStrengthUnits: '/products/manage-strengthUnits',
    manageProductFlavors: '/products/manage-flavors',
    manageProductUsages: '/products/manage-usages'
  },
  crm: {
    manageCompanies: '/companies/manage-companies'
  }
};
