import {
  Box,
  Button,
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Heading,
  HStack,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure
} from '@chakra-ui/react';
import { useMemo } from 'react';
import { CheckoutModal } from '../checkout/checkout.tsx';
import { useShoppingCartData } from './hooks/useShoppingCartData.tsx';
import { CartItem } from './components/CartItem.tsx';
import { PriceTag } from '../../../components/common/PriceTag.tsx';

type ShoppingCartProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const ShoppingCart = (props: ShoppingCartProps) => {
  const { isOpen, onClose } = props;

  const checkoutDisclosure = useDisclosure();
  const { combinedItems, removeItem, setQuantity } = useShoppingCartData();

  const sum = useMemo(() => {
    let total = 0;
    combinedItems.forEach((item) => {
      if (item.Price && item.Quantity) {
        total += item.Price * item.Quantity;
      }
    });

    return total;
  }, [combinedItems]);

  return (
    <Box height='100vh'>
      <Drawer isOpen={isOpen} onClose={onClose} size='md'>
        <DrawerOverlay />
        <DrawerContent bg={useColorModeValue('white', 'gray.800')} overflowY='auto'>
          <DrawerCloseButton size='lg' right={{ base: '4', md: '8' }} top='4' bg='inherit' />
          <Stack padding={{ base: '6', md: '10' }} height='full' spacing='8' overflowY='auto'>
            <Heading size='md'>{'Shopping Cart' + ` (${combinedItems.length} items)`}</Heading>
            <Stack spacing={{ base: '8', md: '10' }}>
              {combinedItems.map((item, key) => {
                return (
                  <CartItem
                    key={key}
                    onChangeQuantity={(quantity: number) => {
                      setQuantity(item.StorageId, quantity);
                    }}
                    onClickDelete={() => {
                      removeItem(item.StorageId);
                    }}
                    model={item}
                  />
                );
              })}
            </Stack>
          </Stack>
          <Stack borderTopWidth='1px' px={{ base: '6', md: '10' }} py='4' spacing='5'>
            <Stack>
              <HStack fontSize='xl' fontWeight='semibold'>
                <Text flex='1'>Subtotal:</Text>
                <PriceTag minimumPrice={sum} />
              </HStack>
            </Stack>
            <Button
              isDisabled={combinedItems.length == 0}
              colorScheme='blue'
              size='lg'
              fontSize='md'
              onClick={() => {
                props.onClose();
                checkoutDisclosure.onOpen();
              }}>
              Checkout
            </Button>
          </Stack>
        </DrawerContent>
      </Drawer>
      {/*really hacky way to clear form on close because reset isnt working*/}
      {checkoutDisclosure.isOpen && <CheckoutModal disclosure={checkoutDisclosure} />}
    </Box>
  );
};
