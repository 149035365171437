import { Box, Button, HStack, IconButton, Select, Text } from '@chakra-ui/react';
import { ArrowLeftIcon, ArrowRightIcon } from '@chakra-ui/icons';

const ButtonPagination = (props: any) => {
  const { children, index, setPageIndex, pageIndex } = props;

  return (
    <Button
      onClick={() => {
        setPageIndex(index);
      }}
      variant={pageIndex === index ? 'tablePagerActive' : 'tablePager'}>
      {children}
    </Button>
  );
};

type ChakraTablePagerProps = {
  pageSize: number;
  setPageSize: (pageSize: number) => void;
  pageIndex: number;
  setPageIndex: (pageIndex: number) => void;
  totalItemsCount: number;
  pageSizeOptions?: Array<number>;
  showOptions: boolean;
  showQuantity: boolean;
};
export const ChakraTablePager = (props: ChakraTablePagerProps) => {
  const {
    pageSize,
    setPageSize,
    pageIndex,
    setPageIndex,
    totalItemsCount,
    pageSizeOptions = props.pageSizeOptions ?? [50, 75, 100],
    showOptions = true,
    showQuantity = false
  } = props;

  const showButtons = () => {
    const buttons = [];

    const totalIndex = Math.ceil(totalItemsCount / pageSize);

    const rangeSize = 5;
    let start = pageIndex - Math.floor(rangeSize / 2);
    start = Math.max(start, 0);
    const end = Math.min(start + rangeSize - 1, totalIndex - 1);

    // Adjust start if we are too close to the end
    if (end === totalIndex - 1) {
      start = Math.max(end - rangeSize + 1, 0);
    }

    // Generate the page numbers in the range
    const pageRange = [];
    for (let i = start; i <= end; i++) {
      pageRange.push(i);
    }

    pageRange.forEach((val, index) => {
      buttons.push(
        <ButtonPagination key={index} setPageIndex={setPageIndex} index={val} pageIndex={pageIndex}>
          {val + 1}
        </ButtonPagination>
      );
    });

    buttons.unshift(
      <IconButton
        key='previous'
        icon={<ArrowLeftIcon />}
        size='sm'
        aria-label='Previous'
        onClick={() => {
          setPageIndex(pageIndex - 1);
        }}
        isDisabled={!(pageIndex > 0)}
        variant='link'></IconButton>
    );

    buttons.push(
      <IconButton
        key='next'
        icon={<ArrowRightIcon />}
        size='sm'
        aria-label='Next'
        onClick={() => {
          setPageIndex(pageIndex + 1);
        }}
        isDisabled={!(pageIndex + 1 < totalIndex)}
        variant='link'></IconButton>
    );

    return buttons;
  };

  return (
    <HStack w='100%' p={2}>
      <HStack w='40%'>
        {showOptions && (
          <>
            <Text fontSize='sm' marginBottom={0} marginTop={0}>
              Page Size:{' '}
            </Text>
            <Select
              w='auto'
              size='sm'
              variant='unstyled'
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}>
              {pageSizeOptions.map((opt, key) => (
                <option key={key} value={opt}>
                  {opt}
                </option>
              ))}
            </Select>

            {showQuantity && (
              <Text marginBottom={0} marginTop={0} fontSize='sm'>
                Total: {totalItemsCount}
              </Text>
            )}
          </>
        )}
      </HStack>
      <Box w='60%' justifyContent='right' display='flex'>
        <HStack>{showButtons()}</HStack>
      </Box>
    </HStack>
  );
};
