import { UseFormReturn } from 'react-hook-form';
import { CheckoutFormModel } from '../types.ts';
import { createFieldHelper } from '@cksoftware/react-base';
import { Flex } from '@chakra-ui/react';
import { useCheckoutFormFields } from '../util/useCheckoutFormFields.tsx';

type Props = {
  formMethods: UseFormReturn<CheckoutFormModel>;
};

export const CheckoutForm = (props: Props) => {
  const fieldHelper = createFieldHelper<CheckoutFormModel>(props.formMethods, undefined);
  const fields = useCheckoutFormFields(props.formMethods, fieldHelper);
  const formGap = '8px';

  return (
    <Flex flexDir={'column'} gap={formGap}>
      <Flex gap={formGap} direction={{ base: 'column', md: 'row' }}>
        {fieldHelper.renderFieldHelperInput('AuthorizedUserId', fields)}
        {fieldHelper.renderFieldHelperInput('Refills', fields)}
      </Flex>
      <Flex
        gap={formGap}
        direction={{
          base: 'column',
          md: 'row'
        }}>
        {fieldHelper.renderFieldHelperInput('InstructionsForUse', fields)}
      </Flex>
      <Flex gap={formGap} direction={{ base: 'column', md: 'row' }}>
        {fieldHelper.renderFieldHelperInput('PatientType', fields)}
        {fieldHelper.renderFieldHelperInput('PetName', fields)}
      </Flex>
      <Flex gap={formGap} direction={{ base: 'column', md: 'row' }}>
        {fieldHelper.renderFieldHelperInput('OwnerName', fields)}
        {fieldHelper.renderFieldHelperInput('OwnerPhoneNumber', fields)}
      </Flex>
      <Flex>{fieldHelper.renderFieldHelperInput('DeliveryType', fields)}</Flex>
      <Flex>{fieldHelper.renderFieldHelperInput('DeliveryAddress', fields)}</Flex>
      <Flex>{fieldHelper.renderFieldHelperInput('DeliveryComments', fields)}</Flex>
    </Flex>
  );
};
